export const accessbilityPage = {
    title: `<span>Accessibility</span>`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    googleReviewText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed feugiat nisi. Morbi dictum lorem eu velit facilisis, ut dapibus felis dignissim.`,
    contentSections: [
        {
            description: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. 
            <br><br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.
            <br><br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
            <br><br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. Morbi cursus sem in lectus varius egestas. Fusce at nisl a quam dictum faucibus non sagittis risus. Duis posuere imperdiet sodales.
            <br><br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo. Pellentesque tempus feugiat arcu, ullamcorper hendrerit dolor feugiat sed. Nulla sed neque scelerisque massa finibus vulputate. Duis fermentum consequat scelerisque. 
            <br><br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. `
        }
    ]
}